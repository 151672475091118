import { IS_BROWSER, getFromEnv } from '@shiftsmartinc/remix-utils';

/**
 * These variables are available on both the client and server
 */
export const ALGOLIA_APP_ID = getFromEnv('ALGOLIA_APP_ID');
export const ALGOLIA_API_KEY = getFromEnv('ALGOLIA_API_KEY');
export const ALGOLIA_INSTANT_SEARCH_INDEX = getFromEnv('ALGOLIA_INSTANT_SEARCH_INDEX'); // prettier-ignore
export const AMPLITUDE_KEY = getFromEnv('AMPLITUDE_KEY');
export const API_URL_EXTERNAL = getFromEnv('API_URL_EXTERNAL');
export const API_URL_INTERNAL = getFromEnv('API_URL_INTERNAL');
export const API_URL = IS_BROWSER ? API_URL_EXTERNAL : API_URL_INTERNAL;
export const APP_NAME = 'platform.shiftsmart.com';
export const APP_URL = getFromEnv('APP_URL');
export const APP_VERSION = getFromEnv('APP_VERSION');
export const RADAR_PUBLIC_KEY = getFromEnv('RADAR_PUBLIC_KEY');

/**
 * @description These variables are available on both the client and server.
 * When we're running server side, we're able to skip heading outside of
 * our K8S cluster to get the API (faster).
 */
export const API_GRAPHQL = IS_BROWSER
  ? getFromEnv('API_GRAPHQL')
  : process.env.API_GRAPHQL_INTERNAL!;
